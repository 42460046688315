<template>
  <div>
    <div>
      <div
        style="min-width: 100%"
        class="d-flex flex-wrap justify-sm-space-between justify-center align-center mb-2"
      >
        <v-btn
          v-if="queryData.status === 'ACCEPTED'"
          dense
          outlined
          color="primary"
          class="caption mr-2"
          @click="downloadTemplate()"
        >
          <span v-if="$vuetify.breakpoint.smAndUp" class="mr-2">
            Template Data Excel
          </span>
          <v-icon>mdi-file-excel-box</v-icon>
        </v-btn>
        <v-btn
          class="gradient-primary caption"
          dark
          depressed
          @click="$store.commit('TOGGLE_DIALOG_CALON_SISWA')"
        >
          <span v-if="$vuetify.breakpoint.smAndUp" class="mr-2">
            {{ $t("master_data.student.tab3_data.added_students") }}
          </span>
          <v-icon>mdi-plus-box</v-icon>
        </v-btn>

        <v-btn
          :loading="loadingDownloadExcel"
          :disabled="dataCandidate.length === 0"
          class="gradient-primary ml-2 caption"
          dark
          depressed
        >
          <download-excel
            :fetch="downloadExcel"
            :fields="fields"
            :name="excelFileName"
          >
            <span v-if="$vuetify.breakpoint.smAndUp" class="mr-2">
              {{ $t("app.download") }}
            </span>
            <v-icon>mdi-cloud-download</v-icon>
          </download-excel>
        </v-btn>

        <div class="subtitle-2 font-weight-bold ml-2">
          {{ total }} {{ $t("master_data.student.student") }}
        </div>

        <v-spacer />

        <v-btn
          v-if="queryData.status !== 'ACCEPTED'"
          dense
          outlined
          color="primary"
          class="caption"
          @click="downloadTemplate()"
        >
          <span v-if="$vuetify.breakpoint.smAndUp" class="mr-2">
            Template Data Excel
          </span>
          <v-icon>mdi-file-excel-box</v-icon>
        </v-btn>
        <div v-else class="d-flex align-center">
          <v-tooltip left>
            <template #activator="{ on }">
              <div v-on="on">
                <v-checkbox
                  v-model="isMajorPriority"
                  :label="$t('master_data.student.main_major')"
                  class="mr-2"
                  @change="filterMajor"
                />
              </div>
            </template>
            <span>{{ $t("master_data.student.filter_major") }}</span>
          </v-tooltip>
          <v-autocomplete
            v-model="major"
            :items="majors"
            :label="$t('master_data.student.select_major')"
            :loading="loadingMajorList"
            outlined
            class="select-200 caption"
            dense
            hide-details
            clearable
            item-text="name"
            return-object
            @change="filterMajor"
          />
        </div>
      </div>

      <v-divider class="my-3" />

      <div
        style="min-width: 100%"
        class="d-flex flex-wrap justify-sm-space-between justify-center"
      >
        <v-radio-group
          row
          v-model="queryData.status"
          hide-details
          class="mt-0 align-center"
          @change="
            () => {
              queryData.page = 1;
              getCandidateStudent();
            }
          "
        >
          <v-radio
            :label="$t('master_data.student.unprocessed')"
            value="UNPROCESSED"
          />
          <v-radio
            :label="$t('master_data.student.accepted')"
            value="ACCEPTED"
          />
          <v-radio
            :label="$t('master_data.student.not_accepted')"
            value="REJECTED"
          />
        </v-radio-group>
        <v-spacer />
        <v-text-field
          v-model="queryData.search"
          :class="$vuetify.breakpoint.xsOnly && 'mt-3'"
          :label="$t('app.search')"
          outlined
          hide-details
          dense
          prepend-inner-icon="mdi-magnify"
          style="max-width: 250px"
          v-on:keyup.enter="getCandidateStudent()"
        />
      </div>
    </div>

    <v-row v-if="multipleId.length > 0" class="my-3">
      <v-col cols="12">
        <div class="d-flex justify-end">
          <v-btn
            v-if="queryData.status != 'REJECTED'"
            class="error white--text mr-3"
            icon
            @click="toggleModalConfirm(false, 'REJECT')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-tooltip
            v-if="
              queryData.status == 'ACCEPTED' &&
                major === null &&
                isMandatoryMajor
            "
            left
          >
            <template #activator="{on, attrs}">
              <div v-on="on" v-bind="attrs">
                <v-btn disabled class="primary mr-3" icon>
                  <v-icon>mdi-checkbox-marked-outline</v-icon>
                </v-btn>
              </div>
            </template>
            <span>{{ $t("master_data.student.require_select_major") }}</span>
          </v-tooltip>
          <div v-else>
            <v-btn
              v-if="queryData.status != 'REJECTED'"
              class="primary white--text mr-3"
              icon
              @click="
                queryData.status == 'ACCEPTED'
                  ? actionEntryClass(multipleId, major === null ? [] : [major])
                  : toggleModalConfirm(false, 'ACCEPT')
              "
            >
              <v-icon>mdi-checkbox-marked-outline</v-icon>
            </v-btn>
          </div>
          <v-btn
            v-if="queryData.status != 'UNPROCESSED'"
            class="purple white--text"
            icon
            @click="toggleModalConfirm(false, 'UNREJECT')"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters class="mt-3">
      <v-data-table
        v-model="multipleId"
        :headers="tableHeaders"
        :items="dataCandidate"
        :loading="loading"
        :options.sync="options"
        hide-default-footer
        show-select
        disable-pagination
        class="elevation-0 table-fixed"
        style="width: 100%"
      >
        <template #item.major="{ item, value }">
          <v-row v-if="value.list.length > 1" no-gutters align="start">
            <span v-if="!item.viewMore">
              <span>{{ value.list[0].label }}</span>
            </span>
            <span v-else>
              <div v-for="(ht, index) in value.list" :key="index" class="my-2">
                {{ ht.label }}
              </div>
            </span>

            <v-spacer></v-spacer>

            <v-btn
              small
              icon
              @click="item.viewMore = !item.viewMore"
              :class="item.viewMore ? 'mt-2' : ''"
            >
              <v-icon v-if="item.viewMore">mdi-chevron-up</v-icon>
              <v-icon v-else>mdi-chevron-down</v-icon>
            </v-btn>
          </v-row>
          <v-row v-if="value.list.length == 1" no-gutters>
            {{ value.list[0].label }}
          </v-row>
        </template>
        <template #item.created_at="{ item }">
          {{ dateTimeFormat(item.created_at) }}
        </template>
        <template #item.status_updated_at="{ item }">
          {{ dateTimeFormat(item.status_updated_at) }}
        </template>
        <template #item.download="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                color="success"
                class="mr-1"
                icon
                v-on="on"
                @click="downloadPdf(item)"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t("master_data.download_student_applicant_detail") }}
            </span>
          </v-tooltip>
        </template>
        <template #item.actions="{ item }">
          <v-menu transition="slide-x-transition" bottom right rounded="lg">
            <template #activator="{ on, attrs }">
              <v-btn fab small depressed icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="showConfirmEmail(item)">
                <v-list-item-title>
                  <v-icon class="mr-2" color="primary">mdi-send</v-icon>
                  {{ $t("master_data.table.send_email") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="
                  $router.push({
                    path: 'student-manual',
                    query: { id: item.id, view: true, applicant: true }
                  })
                "
              >
                <v-list-item-title>
                  <v-icon class="mr-2" color="primary">mdi-eye</v-icon>
                  {{ $t("master_data.detail") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="
                  queryData.status == 'UNPROCESSED' ||
                    queryData.status == 'ACCEPTED'
                "
                :disabled="isDisabled"
                @click="toggleModalConfirm(item.id, 'REJECT')"
              >
                <v-list-item-title>
                  <v-icon :color="isDisabled ? 'grey' : 'primary'" class="mr-2">
                    mdi-close
                  </v-icon>
                  {{ $t("master_data.student.decline") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="
                  queryData.status == 'UNPROCESSED' ||
                    queryData.status == 'ACCEPTED'
                "
                :disabled="isDisabled"
                @click="
                  queryData.status == 'ACCEPTED'
                    ? actionEntryClass(item.id, mergeMajor(item.major))
                    : toggleModalConfirm(item.id, 'ACCEPT')
                "
              >
                <v-list-item-title>
                  <v-icon :color="isDisabled ? 'grey' : 'primary'" class="mr-2">
                    mdi-checkbox-marked-outline
                  </v-icon>
                  {{
                    queryData.status == "ACCEPTED"
                      ? $t("master_data.student.go_to_class")
                      : $t("master_data.student.accept")
                  }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="
                  queryData.status == 'ACCEPTED' ||
                    queryData.status == 'REJECTED'
                "
                :disabled="isDisabled"
                @click="toggleModalConfirm(item.id, 'UNREJECT')"
              >
                <v-list-item-title>
                  <v-icon :color="isDisabled ? 'grey' : 'primary'" class="mr-2">
                    mdi-refresh
                  </v-icon>
                  {{ $t("master_data.student.unprocessed") }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <Pagination
        :length="length"
        :total="total"
        :current-page="queryData.page"
        :limit="queryData.limit"
        :handler="paginateChange"
      />
    </v-row>

    <ModalConfirm
      :model="isModalConfirmEmail"
      :title="$t('master_data.table.send_email')"
      :close="
        () => {
          this.isModalConfirmEmail = false;
        }
      "
      :save="sendEmail"
      :loadingBtn="loadingBtnEmail"
      :content="$t('master_data.dialog_email')"
    />

    <ModalConfirm
      :model="isModalConfirm"
      :title="$t('master_data.student.dialog.title_1')"
      :close="toggleModalConfirm"
      :save="save"
      :loadingBtn="loadingBtn"
      :content="contentDialog"
    />

    <DialogEntryClass
      :title="$t('master_data.student.dialog_2.title')"
      :data="dataClassList"
      :model="modelClassList"
      :isLoading="loadingBtnEntry"
      :majorList="majorItems"
      @isOpen="isDialogClass"
      @getData="getDataEntryClass"
      @postEntryClass="postEntryClass"
    />
  </div>
</template>

<script>
import { downloadApplicantDetail, majorList } from "@/api/admin/master";
import { addToClass } from "@/api/admin/master/student";
import {
  unRejectApplicant,
  getApplicant,
  acceptApplicant,
  rejectApplicant
} from "@/api/admin/master/applicant";
import { resendEmail } from "@/api/admin/admin";
import { createMajorList, mandatoryMajor } from "@/utils/major";
import i18n from "@/i18n";

import moment from "moment/moment";
moment.locale("id");

let typingTimer;
const doneTypingInterval = 750;

export default {
  components: {
    Pagination: () => import("@/components/Pagination"),
    ModalConfirm: () => import("../components/ModalConfirm"),
    DialogEntryClass: () => import("./DialogEntryClass")
  },
  filters: {
    dateFormat: value => {
      if (!value) return "";
      return moment(value).format("DD MMM YYYY");
    }
  },
  computed: {
    isMandatoryMajor() {
      return mandatoryMajor();
    },
    excelFileName() {
      switch (this.queryData.status) {
        case "UNPROCESSED":
          return this.$i18n.t("master_data.student.isUnrejected");
        case "ACCEPTED":
          return this.$i18n.t("master_data.student.isAccepted");
        default:
          return this.$i18n.t("master_data.student.isRejected");
      }
    },
    tableHeaders() {
      const receivedOn = {
        text: i18n.t("master_data.table.received_on"),
        value: "status_updated_at",
        sortable: false,
        width: 170
      };
      const rejectOn = {
        text: i18n.t("master_data.table.reject_on"),
        value: "status_updated_at",
        sortable: false,
        width: 170
      };

      const majors = {
        text: i18n.t("master_data.student.majors"),
        value: "major",
        sortable: false,
        width: 180
      };

      let headers = [
        {
          text: i18n.t("master_data.table.name"),
          value: "name",
          width: 130
        },
        {
          text: "Email",
          value: "email",
          width: 100
        },
        {
          text: i18n.t("master_data.table.phone"),
          sortable: false,
          value: "phone"
        },
        {
          text: i18n.t("master_data.table.date_register"),
          value: "created_at",
          width: 180
        },
        {
          text: i18n.t("app.download"),
          value: "download",
          sortable: false,
          width: 80
        },
        {
          text: i18n.t("app.action"),
          value: "actions",
          sortable: false,
          align: "center"
        }
      ];
      switch (this.queryData.status) {
        case "ACCEPTED":
          headers.splice(2, 0, receivedOn);
          headers.splice(4, 0, majors);
          break;
        case "REJECTED":
          headers.splice(2, 0, rejectOn);
          break;
        case "UNPROCESSED":
          headers.splice(3, 0, majors);
          break;
      }

      return headers;
    },
    fields() {
      let result = {
        [i18n.t("app.name")]: {
          field: "name",
          callback: value => this.preprocessLongNum(value)
        },
        Email: "email",
        [i18n.t("master_data.table.received_on")]: {
          field: "status_updated_at",
          callback: value => this.dateTimeFormat(value)
        },
        [i18n.t("master_data.table.reject_on")]: {
          field: "status_updated_at",
          callback: value => this.dateTimeFormat(value)
        },
        [i18n.t("master_data.table.phone")]: {
          field: "phone",
          callback: value => this.preprocessLongNum(value)
        },
        [i18n.t("master_data.student.majors")]: {
          field: "major",
          callback: value => this.mergeMajor(value).map(v => v.name)
        },
        [i18n.t("master_data.table.date_register")]: {
          field: "created_at",
          callback: value => this.dateTimeFormat(value)
        }
      };
      switch (this.queryData.status) {
        case "UNPROCESSED":
          delete result[i18n.t("master_data.table.received_on")];
          delete result[i18n.t("master_data.table.reject_on")];
          break;
        case "ACCEPTED":
          delete result[i18n.t("master_data.table.reject_on")];
          break;
        case "REJECTED":
          delete result[i18n.t("master_data.table.received_on")];
          delete result[i18n.t("master_data.student.majors")];
          break;
      }
      return result;
    }
  },
  data() {
    return {
      majorItems: [],
      major: null,
      majors: [],
      loadingMajorList: false,
      isMajorPriority: false,
      dataCandidate: [],
      loadingDownloadExcel: false,
      loading: false,
      checkAll: false,
      isModalConfirmEmail: false,
      loadingBtnEmail: false,
      lookTab: "",
      multipleId: [],
      singleId: [],
      options: {},
      total: 0,
      length: 0,
      queryData: {
        page: 1,
        limit: 10,
        sort: [],
        order: "DESC",
        search: "",
        type: "STUDENT",
        status: "UNPROCESSED"
      },
      isModalConfirm: false,
      isDisabled: false,
      loadingBtn: false,
      contentDialog: "",
      status: {
        isAccepted: false,
        isRejected: false,
        isUnrejected: false
      },
      dataClassList: [],
      modelClassList: false,
      dataEntryClass: {
        student: [],
        class: 0,
        school_year: 0
      },
      loadingBtnEntry: false,
      bodyEmail: {
        person: 0,
        email: "",
        type: ""
      }
    };
  },
  mounted() {
    this.getCandidateStudent();
    this.checkAfter();
    this.getMajors();
  },
  watch: {
    "$route.query.tab"(newVal) {
      if (newVal == 2) {
        this.getCandidateStudent();
      } else {
        this.queryData.status = "UNPROCESSED";
      }
    },
    multipleId() {
      this.multipleId.length > 0
        ? (this.isDisabled = true)
        : (this.isDisabled = false);
    },
    "queryData.search"(newVal) {
      if (newVal) {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(() => {
          this.getCandidateStudent();
        }, doneTypingInterval);
      }
    },
    options: {
      handler(param) {
        if (param.sortBy.length != 0) {
          this.loading = true;
          this.queryData.sort = [param.sortBy[0]];
          this.queryData.page = param.page;
          this.queryData.limit = param.itemsPerPage;
          this.getCandidateStudent();
        }
      }
    }
  },
  methods: {
    preprocessLongNum(value) {
      if (String(value).startsWith("0x")) {
        return value;
      }
      if (!isNaN(value) && value != "") {
        return `="${value}"`;
      }
      return value;
    },
    dateTimeFormat(value) {
      if (!value) return "";
      return moment(value).format("DD MMM YYYY, HH:mm:ss");
    },
    filterMajor() {
      this.getCandidateStudent({
        ...this.queryData,
        is_primary: this.isMajorPriority,
        major: this.major
      });
    },
    getMajors() {
      this.loadingMajorList = true;
      this.majors = [];
      majorList()
        .then(res => {
          if (res.data.code) {
            const data = res.data.data;
            this.majors = createMajorList(data);
          } else {
            console.error(res.data.message);
          }
        })
        .catch(e => console.error(e))
        .finally(() => (this.loadingMajorList = false));
    },
    checkAfter() {
      if (this.$route.query.tab === "2" && this.$route.query.radio === "2") {
        this.queryData.status = "ACCEPTED";
      } else {
        this.queryData.status = "UNPROCESSED";
      }
    },
    showConfirmEmail(item) {
      let type;
      switch (this.queryData.status) {
        case "UNPROCESSED":
          type = "MAIL_PPDB_APPLIED_STUDENT";
          break;
        case "ACCEPTED":
          type = "MAIL_PPDB_ACCEPT_STUDENT";
          break;
        case "REJECTED":
          type = "MAIL_PPDB_REJECT_STUDENT";
          break;
      }
      const body = {
        person: item.person,
        email: item.email,
        type: type
      };
      this.bodyEmail = body;
      this.isModalConfirmEmail = true;
    },
    async sendEmail() {
      this.loadingBtnEmail = true;
      const res = await resendEmail(this.bodyEmail);
      if (res.data.code) {
        this.snackBar(true, res.data.message);
      } else {
        this.snackBar(false, res.data.message);
      }
      this.loadingBtnEmail = false;
      this.isModalConfirmEmail = false;
    },
    async downloadPdf(item) {
      this.$store.commit("TOGGLE_FULL_LOADING");
      downloadApplicantDetail({ person: item.person })
        .then(res => {
          if (res.data.code) {
            window.open(res.data.data.path);
          } else {
            this.snackBar(false, res.data.message);
          }
          this.$store.commit("TOGGLE_FULL_LOADING");
        })
        .catch(() => this.$store.commit("TOGGLE_FULL_LOADING"));
    },
    async downloadExcel() {
      this.loadingDownloadExcel = true;
      const query = JSON.parse(JSON.stringify(this.queryData));
      query.limit = this.total;
      query.page = 1;

      const res = await getApplicant(query);
      this.loadingDownloadExcel = false;
      if (res.data.code) {
        if (res.data.data.data.length == 0) {
          this.snackBar(false, this.$i18n.t("app.data_not_found"));
        } else return res.data.data.data;
      }
    },
    getCandidateStudent(param = this.queryData) {
      this.multipleId = [];
      this.dataCandidate = [];
      this.status.isAccepted = false;
      this.status.isRejected = false;
      this.status.isUnrejected = false;
      this.loading = true;
      getApplicant(param)
        .then(res => {
          if (res.data.code) {
            const r = res.data.data;
            this.total = r.total;
            this.length = r.last_page;
            if (r.data.length) {
              r.data.map(item => {
                item.major.list = [];
                item.viewMore = false;
                if (item.major.major_primary) {
                  item.major.list.push(item.major.major_primary);
                }
                if (item.major.major_secondary.length) {
                  item.major.list.push(...item.major.major_secondary);
                }
              });
            }
            this.dataCandidate = r.data;
          } else {
            this.snackBar(false, this.$i18n.t("app.no_message_error"));
          }
        })
        .catch(e => console.error(e))
        .finally(() => (this.loading = false));
    },
    paginateChange(page, limit) {
      this.queryData.page = page;
      this.queryData.limit = limit;
      this.getCandidateStudent();
    },
    downloadTemplate() {
      window.open(process.env.VUE_APP_URL_EXCEL_STUDENT, "_self");
    },
    actionEntryClass(d, majorItems) {
      this.modelClassList = true;
      this.singleId.push(d);
      this.majorItems = majorItems;
    },
    mergeMajor(majorsStudent) {
      const majors = [];
      const majorPrimary = majorsStudent.major_primary;
      const majorSecondary = majorsStudent.major_secondary;
      if (majorPrimary !== null)
        majors.push({ ...majorPrimary, name: majorPrimary.label });
      if (majorSecondary.length !== 0)
        majorSecondary.map(v => majors.push({ ...v, name: v.label }));
      return majors;
    },
    getDataEntryClass(d) {
      if (d.data) {
        switch (d.type) {
          case "school_year":
            this.dataEntryClass.school_year = d.data.id;
            break;
          case "select_class":
            this.dataEntryClass.class = d.data;
            break;
          default:
            break;
        }
        this.multipleId.length > 0
          ? (this.dataEntryClass.student = this.multipleId.map(d => d.id))
          : (this.dataEntryClass.student = this.singleId);
      }
    },
    resetIdStudent() {
      this.singleId = [];
      this.multipleId = [];
    },
    isDialogClass() {
      this.modelClassList = !this.modelClassList;
      if (!this.modelClassList) this.majorItems = [];
    },
    save() {
      if (this.status.isAccepted) {
        this.accepted();
      } else if (this.status.isRejected) {
        this.rejected();
      } else {
        this.unRejected();
      }
    },
    postEntryClass() {
      this.loadingBtnEntry = true;
      return new Promise(resolve => {
        addToClass(this.dataEntryClass)
          .then(res => {
            if (res.data.code) {
              this.snackBar(
                true,
                this.$i18n.t("master_data.student.response.success_entry_class")
              );
            } else {
              this.snackBar(false, res.data.message);
            }
            resolve(res);
          })
          .catch(e => console.error(e))
          .finally(() => {
            this.loadingBtnEntry = false;
            this.resetIdStudent();
            this.getCandidateStudent();
            this.isDialogClass();
          });
      });
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    accepted() {
      this.loadingBtn = true;
      if (this.multipleId.length > 0 || this.singleId.length > 0) {
        acceptApplicant({
          id:
            this.multipleId.length > 0
              ? this.multipleId.map(d => d.id)
              : this.singleId
        })
          .then(res => {
            if (res.data.code) {
              this.resetIdStudent();
              this.snackBar(
                true,
                this.$i18n.t(
                  "master_data.student.response.success_accept_student"
                )
              );
              this.toggleModalConfirm();
              this.getCandidateStudent();
            } else {
              this.snackBar(false, res.data.message);
            }
            this.loadingBtn = false;
          })
          .catch(() => {
            this.loadingBtn = false;
          });
      }
    },
    rejected() {
      this.loadingBtn = true;
      if (this.multipleId.length > 0 || this.singleId.length > 0) {
        rejectApplicant({
          id:
            this.multipleId.length > 0
              ? this.multipleId.map(d => d.id)
              : this.singleId
        })
          .then(res => {
            if (res.data.code) {
              this.multipleId = [];
              this.singleId = [];
              this.snackBar(
                true,
                this.$i18n.t(
                  "master_data.student.response.success_reject_student"
                )
              );
              this.toggleModalConfirm();
              this.getCandidateStudent();
            } else {
              this.snackBar(false, res.data.message);
            }
            this.loadingBtn = false;
          })
          .catch(() => {
            this.loadingBtn = false;
          });
      }
    },
    unRejected() {
      this.loadingBtn = true;
      if (this.multipleId.length > 0 || this.singleId.length > 0) {
        unRejectApplicant({
          id:
            this.multipleId.length > 0
              ? this.multipleId.map(d => d.id)
              : this.singleId
        })
          .then(res => {
            if (res.data.code) {
              this.multipleId = [];
              this.singleId = [];
              this.snackBar(
                true,
                this.$i18n.t("master_data.student.response.success_unprocessed")
              );
              this.toggleModalConfirm();
              this.getCandidateStudent();
            } else {
              this.snackBar(false, res.data.message);
            }
            this.loadingBtn = false;
          })
          .catch(() => {
            this.loadingBtn = false;
          });
      }
    },
    checkHandler(id) {
      const isExist = this.multipleId.filter(v => v === id).length > 0;
      if (isExist) {
        this.multipleId = this.multipleId.filter(v => v !== id);
      } else {
        this.multipleId.push(id);
      }

      if (this.multipleId.length !== this.dataCandidate.length)
        this.checkAll = false;
    },
    checkAllHandler() {
      this.multipleId = [];
      if (this.checkAll) {
        this.dataCandidate.map(v => this.multipleId.push(v.id));
      }
    },
    toggleModalConfirm(id, action) {
      if (id) {
        this.singleId.push(id);
      }
      if (action == "ACCEPT") {
        this.status.isAccepted = true;
        this.contentDialog = this.$i18n.t(
          "master_data.student.dialog.content_1"
        );
      } else if (action == "REJECT") {
        this.status.isRejected = true;
        this.contentDialog = this.$i18n.t(
          "master_data.student.dialog.content_2"
        );
      } else {
        this.status.isUnrejected = true;
        this.contentDialog = this.$i18n.t(
          "master_data.student.dialog.content_3"
        );
      }
      this.isModalConfirm = !this.isModalConfirm;
    }
  }
};
</script>
