import request from "@/utils/request";

export function addToClass(data) {
  return request({
    method: "post",
    url: "student/add_to_class",
    data: data
  });
}

export function getByID(data) {
  return request({
    method: "post",
    url: "student/get_byid",
    data: data
  });
}

export function studentsList(data) {
  return request({
    method: "post",
    url: "student/list",
    data: data
  });
}

export function studentUpdate(data) {
  return request({
    method: "post",
    url: "student/update_student",
    data: data
  });
}

export function updateNIS(data) {
  return request({
    method: "post",
    url: "student/update_nis",
    data: data
  });
}

export function saveAction(data) {
  return request({
    method: "post",
    url: "student/move_class",
    data: data
  });
}

export function studentProfiling(data) {
  return request({
    method: "post",
    url: "student/student-profile",
    data: data
  });
}

export function deleteStudentMutation(data) {
  return request({
    method: "post",
    url: "/student/permanent_delete_student",
    data: data
  });
}
