import store from "@/store";

export function mandatoryMajor() {
  const schoolLevel = store.getters.g_level_school;
  return schoolLevel === "SMK" || schoolLevel === "MAK";
}

export function createMajorList(majors = []) {
  const listMajors = [];
  const schoolLevel = store.getters.g_level_school;
  if (schoolLevel === "SMP" || schoolLevel === "SMA") {
    majors.forEach(e => {
      if (e.reference.length === 0) {
        listMajors.push({ name: e.major, major_id: e.major_id });
      }
    });
  }
  if (schoolLevel === "SMK" || schoolLevel === "MAK") {
    majors.forEach((e, i, a) => {
      const majorId = e.major_id;
      const majorName = e.major;
      e.reference.forEach(x => {
        const expertiseId = x.expertise_id;
        listMajors.push({
          header: majorName.concat(" - ").concat(x.expertise_program)
        });
        x.expertise_competency.forEach(y => {
          listMajors.push({
            expertise_competency_id: y.expertise_competency_id,
            name: y.expertise_competency,
            expertise_id: expertiseId,
            major_id: majorId
          });
        });
        if (a.length !== i + 1) listMajors.push({ divider: true });
      });
    });
  }
  return listMajors;
}
